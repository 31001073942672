<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn :disabled="loading" v-on="on" @click="getItems()" icon>
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>
      <wiki slug="serices-telephony-overview" />
    </v-toolbar>
    <v-card-text>
      <data-table-extended
        :headers="headers"
        :items="items"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        :search="search"
        :custom-filter="customFilter"
        sort-by="subscription_number"
        :hide-default-footer="items.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
        @item-expanded="
          createVoip = false;
          createMobile = false;
        "
        local-storage-name="phoneList"
      >
        <template v-slot:top>
          <v-text-field
            class="mb-4"
            v-model="search"
            append-outer-icon="mdi-magnify"
            clear-icon="mdi-close-circle"
            :clearable="!isMobile"
            v-bind:label="$t('search')"
            single-line
            hide-details
          />
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="
                  $router.push({
                    name: 'phone-service-details',
                    params: { productId: item.product_id },
                  })
                "
                text
                small
                icon
              >
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("open telephony subscription details") }}</span>
          </v-tooltip>
        </template>
      </data-table-extended>
    </v-card-text>
  </v-card>
</template>

<script>
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";
import DataTableExtended from "@/components/basics/DataTableExtended";

export default {
  name: "Phone",
  props: {
    productId: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  mixins: [isMobile],
  components: {
    Wiki,
    DataTableExtended,
  },
  data: () => ({
    items: [],
    loading: false,
    search: "",
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("product name"),
          value: "product_name",
        },
        {
          text: this.$i18n.t("product descr"),
          value: "product_descr",
        },
        {
          text: this.$i18n.t("subscription number"),
          value: "subscription_number",
        },
        { text: "", value: "action", sortable: false },
      ];
    },
  },
  watch: {
    "$store.state.session": function () {
      this.items = [];
      this.getItems();
    },
  },
  methods: {
    customFilter(value, search, item) {
      return (
        (item.subscription_number != null &&
          item.subscription_number.indexOf(search) >= 0) ||
        (item.product_name != null && item.product_name.indexOf(search) >= 0) ||
        (item.product_descr != null && item.product_descr.indexOf(search) >= 0)
      );
    },
    getItems: function () {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/phone", {
          params: {
            product_id: this.productId,
          },
        })
        .then((response) => {
          that.items = response.data;
        })
        .catch((err) => {
          if (err.invalid()) {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<i18n>
{
  "en": {
    "product name": "Product name",
    "product descr": "Product description",
    "add mobile subscription": "add mobile subscription",
    "add voip subscription": "add VoIP subscription",
    "reload": "reload",
    "search": "Search",
    "open telephony subscription details": "open telephony subscription details",
    "subscription number": "subscription number"
  }, 
  "de": {
    "add mobile subscription": "Mobil Abonnement hinzufügen",
    "add voip subscription": "VoIP Abonnement hinzufügen",
    "reload": "neu laden",
    "search": "Suche",
    "open telephony subscription details": "öffne Telefonie Abo Details",
    "subscription number": "Abonnement Nummer",
    "product name": "Produktname",
    "product descr": "Produktbeschreibung"
  }
}
</i18n>
